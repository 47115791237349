.reset-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-left: 40%;
    animation: slideInRight 0.5s ease-in-out !important;
  }
  
  .rp_img {
    height: 96px;
    width: 96px;
  }
  
  .rp_innerBox {
    min-width: 400px;
    height: fit-content;
    width: fit-content;
    background-color: #fff;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    padding: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 9%;
  }
  
  .rp_h1 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .rp_form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .rp_label {
    margin-bottom: 10px;
  }
  
  .rp_input {
    width: 300px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .rp_btn {
    width: 200px;
    padding: 10px;
    margin-top: 20px;
    font-size: 16px;
    background-color: darkturquoise;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .rp_btn:hover {
    background: rgb(19, 175, 240);
  }
  
  .rp_success-message {
    color: green;
    margin-top: 20px;
  }
  
  .rp_error-message {
    color: red;
    margin-top: 20px;
  }
  
  @media screen and (max-width: 900px) {
    .rp_innerBox {
      width: 90%;
      max-width: 400px;
    }
  }
  
  @media screen and (max-width: 500px) {
    .rp_innerBox {
      width: 90%;
      max-width: 300px;
  
    }
    .reset-password-container{
      margin-left: 0px;
    }
  }
  @keyframes slideInRight {
    0% {
      transform: translateX(7%); 
      opacity: 0;
    }
    100% {
      transform: translateX(0); 
      opacity: 1;
    }
  }
  