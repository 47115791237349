::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  .btn-secondary{
    width: 12vw !important;  
    text-wrap: wrap !important;
  }
  .dropdown-item {
    border-radius: 0;
    width: 12vw !important;
    white-space: normal; /* Allow text to wrap */
    text-wrap: wrap !important;
  }
  

.bg-dark {
    background-color: #14183e !important;
}
.prebuilt{
  background-image: linear-gradient(#f3f3f3 2px, transparent 2px), linear-gradient(to right, #f3f3f3 2px, transparent 2px);
  background-size: 35px 35px;
  background-color: #ffffff;
  margin-left: 20%;
  width: 100%;
  padding-bottom: 10%;
}

.dots {
  width: 56px;
  height: 26.9px;
  background: radial-gradient(circle closest-side,#f8f8ff 90%,#0000) 0%   50%,
         radial-gradient(circle closest-side,#f8f8ff 90%,#0000) 50%  50%,
         radial-gradient(circle closest-side,#f8f8ff 90%,#0000) 100% 50%;
  background-size: calc(100%/3) 13.4px;
  background-repeat: no-repeat;
  animation: dots-7ar3yq 1s infinite linear;
}

@keyframes dots-7ar3yq {
  20% {
     background-position: 0%   0%, 50%  50%,100%  50%;
  }

  40% {
     background-position: 0% 100%, 50%   0%,100%  50%;
  }

  60% {
     background-position: 0%  50%, 50% 100%,100%   0%;
  }

  80% {
     background-position: 0%  50%, 50%  50%,100% 100%;
  }
}