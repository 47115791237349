*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App{
  padding: 5px;
  height: 98vh !important;
  width: 98vw;
  display: flex;
}
.react-chatbot-kit-chat-container {
  position: relative;
  width: 68vw !important;
  max-height: 90vh !important;
  max-width: 68vw !important;
  height: 70vh !important;
  margin-left: 35% !important;
  background-color: white !important;

  transition: transform 0.05s ease-in-out !important;
  cursor: pointer !important;
  animation: slideInRight 0.5s ease-in-out !important;
}
.react-chatbot-kit-chat-message-container{
  height: 88vh!important;

}
.react-chatbot-kit-chat-bot-message {
  margin-left: 15px !important;
  width: max-content !important;
  max-width: 70vh !important;
}
.react-chatbot-kit-user-chat-message {
  margin-right: 15px ;
}
.react-chatbot-kit-chat-input-container{
  bottom:-26vh !important;
}
.range{
  position: relative;
  left: 10%;
  margin-top: -50vh;
}

.start{
  font-size: small;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border-style: none;
  width: fit-content;
  padding: 10px;
  border-radius: 30px;
  border-width: 1px;
  margin-left: 5%;
  color: black;
  background: #c6c6c6;
  background: -webkit-linear-gradient(0deg, #c6c6c6 0%, #e6ff93 100%);
  background: linear-gradient(0deg, #c6c6c6 0%, #e6ff93 100%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
}
.start:hover{
  color: white;
  background: rgb(12, 11, 11);
}

.pdfu{
  margin-top: 6vh;
  display: none;
}

.pdf{
  width: 8vw;
  color: #c6c6c6;
  font-size: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
}

.pdf:hover{
  color: white;
  background: black;
}

.upload{
  margin-top: 2vh;
  padding: 2px;
  /* margin-left: 2vw; */
  border-radius: 5px;
  background: #c6c6c6;
  background: -webkit-linear-gradient(0deg, #c6c6c6 0%, #e6ff93 100%);
  background: linear-gradient(0deg, #c6c6c6 0%, #e6ff93 100%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
}
.upload:hover{
  color: white;
  background: black;
}
.updf{
  color: rgb(225, 225, 225);
  line-height: 1px;
  margin-left: 1.6vw;
}

.cb{
  max-height: 100vh;
  max-width: 100vh;
  transition: transform 0.05s ease-in-out;
  cursor: pointer;
  animation: slideInRight 1s ease-in-out;
}
@keyframes slideInRight {
  0% {
    transform: translateX(50%); 
    opacity: 0;
  }
  100% {
    transform: translateX(0); 
    opacity: 1;
  }
}


@media screen and (max-width: 600px)
{
  input[type="range"] {
    width: 8px;
    height: 175px;
    padding: 0 5px;
    -webkit-appearance: slider-vertical;
  }
  .word{
    font-size: 0.4rem;
    margin-left: -5vw;
  }
  .range{
   margin-top: -39vh;
   margin-left: -10vw;
  }
  .react-chatbot-kit-chat-container {
width: 68vw !important;
height: 64vh !important;
  }
  .pdfu{
    margin-left: -4vw;
    margin-top: 10vh;
    text-align: left;
  
  }
  .updf{
    font-size: 0.6rem;
    margin-left: -1vw;
   
  }
.pdf{
  font-size: 5px;
  width: 10vw;
}
input[type="file"] {
  padding: 0px;
}
.upload{
  font-size: 0.6rem;
}
.react-chatbot-kit-chat-bot-message{
  font-size: 10px !important;
  width: 25vw !important;
}
.react-chatbot-kit-user-chat-message{
  font-size: 10px !important;
  width: 25vw !important;
}
.start{
  font-size: 8px;
  margin-left: 8vw;
}

}
.shapes {
  margin-left: 30vw;
  margin-top: -20vh;
  position: absolute;
  width: 44.8px;
  height: 44.8px;
  color: #474bff;
  position: relative;
}

.shapes:before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 50%;
  background: conic-gradient(from 0deg,  #0000, currentColor 1deg 120deg,#0000 121deg) top right,
         conic-gradient(from 120deg,#0000, currentColor 1deg 120deg,#0000 121deg) bottom,
         conic-gradient(from 240deg,#0000, currentColor 1deg 120deg,#0000 121deg) top left;
  background-size: 44.8px 44.8px;
  background-repeat: no-repeat;
  animation: shapes-r0t0iomd 2s infinite cubic-bezier(0.3,1,0,1);
}

@keyframes shapes-r0t0iomd {
  33% {
     inset: -9px;
     transform: rotate(0deg);
  }

  66% {
     inset: -9px;
     transform: rotate(180deg);
  }

  100% {
     inset: 0;
     transform: rotate(180deg);
  }
}